import { Component, Inject, EventEmitter, Output, inject, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { UsuariService } from '../../services/api/usuari.service';
import { Usuari } from '../../types/Usuari';
import { MatTooltip } from '@angular/material/tooltip';

@Component({
  selector: 'app-user-edit',
  template: `
    <div class="ctti-modal text-left" [formGroup]="form">
      <div class="cross" (click)="closeModal()">
        <mat-icon> close </mat-icon>
      </div>
      @if(data.edit) {
      <h5 class="title5"><mat-icon>edit</mat-icon>{{ 'ADMIN.USERS_EDIT' | translate }}</h5>
      } @else {
      <h5 class="title5"><mat-icon>add</mat-icon>{{ 'ADMIN.USERS_TITLE' | translate }}</h5>
      }
      <hr/>
      <div class="info-user">
        <span><mat-icon>info</mat-icon></span>
        <p><span>{{ 'ADMIN.IMPORTANT' | translate }}</span>{{ 'ADMIN.USERS_SUB' | translate }}{{raoEmpresa}}</p>
      </div>
      <br />
      <div class="grid-2">
        <div class="col mb-3">
          <label for="dniUsuari"><span class="red">*</span>{{ 'REGISTRE.PROFESSIONAL_NIF_LABEL' | translate }}:</label>
          @if(data.edit) {
          <input
            type="text"
            id="dniUsuari"
            class="ctti-input-text"
            formControlName="dniUsuariDisabled"
            placeholder="{{ 'REGISTRE.PROFESSIONAL_NIF_PLACEHOLDER' | translate }}"
            [disabled]="true"
            
          />
          } @else {
            <input
            type="text"
            id="dniUsuari"
            class="ctti-input-text"
            formControlName="dniUsuari"
            placeholder="{{ 'REGISTRE.PROFESSIONAL_NIF_PLACEHOLDER' | translate }}"
            matTooltip="{{ tooltipText | translate }}"
            #dniTooltipRef="matTooltip"
            matTooltipPosition="above"
            maxlength="12"
          />
          }
        </div>
        <div class="col mb-3">
          <label for="nom">{{ 'REGISTRE.PROFESSIONAL_NAME_LABEL' | translate }}:</label>
          <input
            type="text"
            id="nom"
            class="ctti-input-text"
            formControlName="nom"
            placeholder="{{ 'REGISTRE.PROFESSIONAL_NAME_PLACEHOLDER' | translate }}"
            matTooltip="{{ tooltipText | translate }}"
            #nomTooltipRef="matTooltip"
            matTooltipPosition="above"
          />
        </div>
      </div>
      <div class="grid-2">
        <div class="col mb-3">
          <label for="cognom"
            >{{ 'REGISTRE.PROFESSIONAL_FIRST_SURNAME_LABEL' | translate }}:</label
          >
          <input
            type="text"
            id="cognom"
            class="ctti-input-text"
            formControlName="cognom"
            placeholder="{{ 'REGISTRE.PROFESSIONAL_FIRST_SURNAME_PLACEHOLDER' | translate }}"
            matTooltip="{{ tooltipText | translate }}"
            #cognomTooltipRef="matTooltip"
            matTooltipPosition="above"
          />
        </div>
        <div class="col mb-3">
          <label for="secCognom"
            >{{ 'REGISTRE.PROFESSIONAL_SECOND_SURNAME_LABEL' | translate }}:</label
          >
          <input
            type="text"
            id="secCognom"
            class="ctti-input-text"
            formControlName="secCognom"
            placeholder="{{ 'REGISTRE.PROFESSIONAL_SECOND_SURNAME_PLACEHOLDER' | translate }}"
            matTooltip="{{ tooltipText | translate }}"
            #secCognomTooltipRef="matTooltip"
            matTooltipPosition="above"
          />
        </div>
      </div>
      <div class="grid-2">
        <div class="col mb-3">
          <label for="adrecaElectronica">{{ 'REGISTRE.PROFESSIONAL_EMAIL_LABEL' | translate }}:</label>
          <input
            type="text"
            id="adrecaElectronica"
            class="ctti-input-text"
            formControlName="adrecaElectronica"
            placeholder="{{ 'REGISTRE.PROFESSIONAL_EMAIL_PLACEHOLDER' | translate }}"
            matTooltip="{{ tooltipText | translate }}"
            #emailTooltipRef="matTooltip"
            matTooltipPosition="above"
          />
        </div>
        <div class="col mb-3">
          <label for="telefon">{{ 'REGISTRE.PROFESSIONAL_PHONE_LABEL' | translate }}:</label>
          <input
            type="text"
            id="telefon"
            class="ctti-input-text"
            formControlName="telefon"
            placeholder="{{ 'REGISTRE.PROFESSIONAL_PHONE_PLACEHOLDER' | translate }}"
          />
        </div>
      </div>
      <div class="grid-2">
        <div class="col mb-3">
          <label><span class="red">*</span>{{ 'ADMIN.PROFILE' | translate }}:</label>
          <div class="radio-buttons">
            <label>
              <input
                type="radio"
                formControlName="codiPerfil"
                [value]="true"
              />
              {{ 'ADMIN.ADMIN' | translate }}
            </label>
            <label>
              <input type="radio" formControlName="codiPerfil" [value]="false" />
              {{ 'ADMIN.GESTOR' | translate }}
            </label>
          </div>
        </div>
        <div class="col mb-3">
          <label><span class="red">*</span>{{ 'STMC.STATUS' | translate }}:</label>
          <div class="radio-buttons">
            <label>
              <input type="radio" formControlName="estat" [value]="true" />
              {{ 'ADMIN.ACTIU' | translate }}
            </label>
            <label>
              <input type="radio" formControlName="estat" [value]="false" />
              {{ 'ADMIN.INACTIU' | translate }}
            </label>
          </div>
        </div>
      </div>
      <br /><br />
      <div class="botones">
        <button
          class="ctti-button ctti-button-trans"
          mat-dialog-close
          (click)="closeModal()"
        >
          {{ 'MODAL.CANCEL' | translate }}
        </button>
        @if(data.edit) {
        <button
          class="ctti-button ctti-button-filled"
          mat-dialog-close
          (click)="closeModal(true, true)"
        >
          <mat-icon>save</mat-icon>
          {{ 'MODAL.SAVE_CANVIS' | translate }}
        </button>
        } @else {
        <button
          class="ctti-button ctti-button-filled"
          mat-dialog-close
          (click)="closeModal(true)"
        >
        <mat-icon>save</mat-icon>
          {{ 'MODAL.SAVE' | translate }}
        </button>
        }
      </div>
    </div>
  `,
})
export class UserEditComponent {
  userService = inject(UsuariService);
  @Output() closeEvent = new EventEmitter<void>();
  form: FormGroup;
  rowdata: any;
  usuari!: Usuari;
  tooltipText: string = '';
  isFocused: boolean = false;
  resultados: Usuari[] = [];
  raoEmpresa: string = '';
  @ViewChild('dniTooltipRef') dniTooltipRef!: MatTooltip;
  @ViewChild('emailTooltipRef') emailTooltipRef!: MatTooltip;
  @ViewChild('nomTooltipRef') nomTooltipRef!: MatTooltip;
  @ViewChild('cognomTooltipRef') cognomTooltipRef!: MatTooltip;
  @ViewChild('secCognomTooltipRef') secCognomTooltipRef!: MatTooltip;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { user: Usuari; edit: boolean },
    private fb: FormBuilder,
    private dialog: MatDialog
  ) {
    this.form = this.fb.group({
      dniUsuari: ['', Validators.required],
      dniUsuariDisabled: [{ value: '', disabled: true }, Validators.required],
      nom: [
        '',
        [Validators.required, Validators.pattern(/^[a-zA-ZÀ-ÿ\u00f1\u00d1\u00e7\u00c7\s]+$/)]
      ],
      cognom: [
        '',
        [Validators.required, Validators.pattern(/^[a-zA-ZÀ-ÿ\u00f1\u00d1\u00e7\u00c7\s]+$/)]
      ],
      secCognom: [
        '',
        Validators.pattern(/^[a-zA-ZÀ-ÿ\u00f1\u00d1\u00e7\u00c7\s]*$/)
      ],
      adrecaElectronica: [
        '',
        [Validators.required, Validators.pattern(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)]
      ],
      telefon: [''],
      codiPerfil: true,
      estat: [true, Validators.required]
    });
    
  }

  async ngOnInit(): Promise<void> {
    const empresa = JSON.parse(localStorage.getItem("empresa") || "{}");
    this.raoEmpresa = empresa.raoSocial || "";
    if (this.data.user) {
      this.rowdata = this.data.user;
      this.form.patchValue({
        dniUsuari: this.rowdata.dniUsuari,
        dniUsuariDisabled: this.rowdata.dniUsuari,
        nom: this.rowdata.nom,
        cognom: this.rowdata.cognom,
        secCognom: this.rowdata.secCognom,
        adrecaElectronica: this.rowdata.adrecaElectronica,
        telefon: this.rowdata.telefon,
        codiPerfil: this.rowdata.codiPerfil.includes("ADMIN"),
        estat: this.rowdata.estat
      });
    }
  }

  validateFields() {
    this.tooltipText = "MODAL.OBLIGATORI";
    setTimeout(() => {
      this.tooltipText = "";
    }, 2000);
  
    const fields = [
      { key: 'dniUsuari', tooltipRef: this.dniTooltipRef, required: true },
      { key: 'adrecaElectronica', tooltipRef: this.emailTooltipRef },
      { key: 'nom', tooltipRef: this.nomTooltipRef },
      { key: 'cognom', tooltipRef: this.cognomTooltipRef },
      { key: 'secCognom', tooltipRef: this.secCognomTooltipRef },
    ];
    let error = false;
    for (const field of fields) {
      const control = this.form.get(field.key);
      if ((!control || control.value === '') && field.required) {
        error = true;
        this.tooltipText = "MODAL.OBLIGATORI";
      } else if(control?.invalid && control.value) {
        error = true;
        this.tooltipText = "MODAL.FORMAT_INVALID";
      } else if(field.key.includes("email") && control?.value && !/^[a-zA-Z0-9_]+([.][a-zA-Z0-9_]+)*@[a-zA-Z0-9_]+([.][a-zA-Z0-9_]+)*[.][a-zA-Z]{2,5}$/.test(control?.value)) {
        this.tooltipText = "MODAL.FORMAT_INVALID";
        error = true;
      }

      if(error) {
        const invalidControl = document.getElementById(field.key);
        if (invalidControl) {
          setTimeout(() => {
            field.tooltipRef.show();
            invalidControl.focus();
          }, 100);
          return false;
        }
      }
    }
    return true;
  }

  async closeModal(save: boolean = false, edit: boolean = false) {
    if (save) {
      if (!this.validateFields()) {
        return;
      }
      this.form.value.codiPerfil = this.form.value.codiPerfil ? "PROF_ADMIN" : "PROF_GEST";
      const id = edit ? this.rowdata.empresaUsuariId.toString() : null;
      this.form.value.dniUsuari = this.form.value.dniUsuari?.toUpperCase() || ""
      this.userService.emitSaveUser(this.form.value, id)
    } else {
      this.dialog.closeAll()
    }
  }
}
