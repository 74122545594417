import { Component, inject, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Stmc } from '../../types/Stmc';
import { MatDialog } from '@angular/material/dialog';
import { lastValueFrom } from 'rxjs';
import { ToastComponent } from '../../shared/toast/toast.component';
import { ErrorModalComponent } from '../../shared/error-modal/error-modal.component';
import { CarregaService } from '../../services/api/carrega.service';
import { DetallCarrega } from '../../shared/detall-carrega/detall-carrega.component';

@Component({
  selector: 'app-carrega',
  templateUrl: './carrega.component.html',
  styleUrl: './carrega.component.scss',
})
export class CarregaComponent {
  @ViewChild(ToastComponent) toast!: ToastComponent;
  carregaService = inject(CarregaService);
  tooltipText: string = "";
  totalRegistres: number = 0;
  dataSource: any[] = [];
  paginatedData: any[] = [];
  pageNumberArr: number[] = [];
  pageSizeOptions: number[] = [5, 10, 20];
  itemsPerPage: number = 5;
  currentPage: number = 1;
  totalPages: number = 1;
  sortField: string = 'dataCarrega';
  sortOrder: boolean = true;
  mostrarEstats: boolean = true;
  formData: FormGroup = this.fb.group({
    resultat: "",
    codiStmc: "",
    dataCarregaFi: "",
    dataCarregaInici: "",
    nomFitxer: ""
  });

  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog
  ) {}

  async ngOnInit() {
    const empresa = localStorage.getItem('empresa') || '{}';
    const parseEmpresa = JSON.parse(empresa);
    if(!parseEmpresa.configuracioSftp.autorizacio) {
      window.location.href = "./#/inici"
    }
    await this.fetchData();
  }

  async fetchData(filters: any = {}) {
    if(this.currentPage == 0) {
      this.currentPage = 1;
    }
    Object.keys(filters).forEach(key => {
      if (filters[key] === "") {
        delete filters[key];
      }
    });
    if(new Date(filters.dataCarregaInici) > new Date(filters.dataCarregaFi)) {
      const dialogRef = this.dialog.open(ErrorModalComponent, {
        disableClose: true,
        data: {
          title: `CARREGA.DATES_INVALIDES`,
          icon: 'warning',
          showCancel: true,
          message: '',
        },
      });
      dialogRef.componentInstance.closeEvent.subscribe(
        async (result: { accept: boolean }) => {
          this.dialog.closeAll();
        }
      );
      return;
    }
    const parseData = (data: string, hora: string) => {
      const date = data.split('-');
      let newDate = date[0].length === 4
        ? `${date[2]}-${date[1]}-${date[0]}`
        : `${date[0]}-${date[1]}-${date[2]}`;
      newDate = newDate.includes(hora) ? newDate : `${newDate} ${hora}`
      return newDate;
    }
    if (filters.dataCarregaFi) {
      filters.dataCarregaFi = parseData(filters.dataCarregaFi, "23:59:59")
    }
    if (filters.dataCarregaInici) {
      filters.dataCarregaInici = parseData(filters.dataCarregaInici, "00:00:00")
    }
    const data = await lastValueFrom(this.carregaService.findBy({
      "dadesPaginacio": {
        "numRegisters": this.itemsPerPage,
        "numPagina": this.currentPage,
        "ordreCamp": this.sortField,
        "ordreAsc": this.sortOrder
      },
      "filtresConsulta": filters
    }))
    this.dataSource = data.dadesResultat;
    this.totalRegistres = data.dadesPaginacio.totalRegisters
    this.totalPages = Math.ceil(
      this.totalRegistres / this.itemsPerPage
    );
    if (this.currentPage > this.totalPages) {
      this.currentPage = this.totalPages;
    }
    this.pageNumberArr = Array.from(
      { length: this.totalPages },
      (_, i) => i + 1
    );
  }

  async applyFilter() {
    this.currentPage = 1;
    await this.fetchData(this.formData.value)
  }

  saltLinia(str: string) {
    if (str.length > 30) {
      return str.substring(0, 30) + '\n' + str.substring(30);
    }
    return str;
  }

  viewElement(element: any) {
    const dialogRef = this.dialog.open(DetallCarrega, {
      disableClose: true,
      data: element ,
    });
    dialogRef.componentInstance.closeEvent.subscribe(() => {
      this.dialog.closeAll();
    });
  }

  novaCarrega = () => {
    const fileInput = document.getElementById('fileInput') as HTMLInputElement;
    fileInput?.click();
  };
  
  handleFileInput = async (event: Event) => {
    const input = event.target as HTMLInputElement;
    if (!input.files || input.files.length === 0) {
      return;
    }
    const file = input.files[0];
    input.value = "";
    // Validar que sea un archivo CSV
    if (file.type !== 'text/csv' && !file.name.endsWith('.csv')) {
      this.dialog.open(ErrorModalComponent, {
        disableClose: true,
        data: {
          title: 'CARREGA.ERROR_PUJADA',
          icon: 'error',
          message: 'CARREGA.NO_CSV',
        },
      });
      return;
    }
    // Validar que el tamaño no exceda 5000 MB
    const maxSize = 5000 * 1024 * 1024;
    if (file.size > maxSize) {
      this.dialog.open(ErrorModalComponent, {
        disableClose: true,
        data: {
          title: 'CARREGA.ERROR_PUJADA',
          icon: 'error',
          message: 'CARREGA.MASSA_PES',
        },
      });
      return;
    }
    try {
      const formData = new FormData();
      formData.append('fitxer', file);
      formData.append('observacions', '');
      const response = await lastValueFrom(this.carregaService.create(formData));
      if(response.validacioResultat){
        const dialogRef = this.dialog.open(ErrorModalComponent, {
          disableClose: true,
          data: {
            title: `CARREGA.ERROR_PUJADA`,
            message: response.validacioResultat[0].missatge,
            icon: 'warning',
            showCancel: false,
          },
        });
        dialogRef.componentInstance.closeEvent.subscribe(() => {
          this.dialog.closeAll();
        });
        return false;
      }
      // Mostrar notificación de éxito
      this.toast.open(
        'Arxiu carregat',
        "S'han carregat els STMCs des del fitxer csv"
      );
      await this.fetchData();
      return true;
    } catch (error) {
      this.dialog.open(ErrorModalComponent, {
        disableClose: true,
        data: {
          title: 'CARREGA.ERROR_PUJADA',
          icon: 'error',
          message: '',
        },
      });
      return false;
    }
  };
  
  async fitxerResultat(id: string) {
    const fitxer = await lastValueFrom(this.carregaService.fileOut(id))
    this.descargaPDF(fitxer.dadesResultat, "text/csv")
  }

  async fitxerEviat(id: string) {
    const fitxer = await lastValueFrom(this.carregaService.fileIn(id))
    this.descargaPDF(fitxer.dadesResultat, "text/csv")
  }

  async dcs(id: string) {
    const fitxer = await lastValueFrom(this.carregaService.fileZip(id))
    this.descargaPDF(fitxer.dadesResultat, "application/zip")
  }

  descargaPDF (dadesResultat: { contingutBase64: string; nomFitxer: string; }, type: string) {
    if(!dadesResultat) {
      const dialogRef = this.dialog.open(ErrorModalComponent, {
        disableClose: true,
        data: {
          title: `CARREGA.NO_FITXER`,
          message: "",
          icon: 'error',
          showCancel: false,
        },
      });
      dialogRef.componentInstance.closeEvent.subscribe(() => {
        this.dialog.closeAll();
      });
      return;
    }
    const binaryString = atob(dadesResultat.contingutBase64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    const blob = new Blob([bytes], { type });
    
    const enlace = document.createElement('a');
    enlace.href = URL.createObjectURL(blob);
    enlace.download = dadesResultat.nomFitxer;
    document.body.appendChild(enlace);
    enlace.click();
    document.body.removeChild(enlace);
  };

  iconaTipus(tipus: string) {
    switch (tipus) {
      case 'DC_MODIFICACIO':
        return 'sd_card_alert';
      case 'DC_CREAT':
        return 'task';
      case 'DC_NO_CREAT':
        return 'error_outline'
    }
    return "";
  }

  showEstats() {
    this.mostrarEstats = !this.mostrarEstats;
  }

  async clearFilter() {
    this.formData.patchValue({
      codiStmc: '',
      nifRaoTransportista: '',
      dataCarregaInici: '',
      dataCarregaFi: '',
      matriculaVehicle: '',
      tipusMercaderia: '',
      poblacioOrigen: '',
      poblacioDesti: '',
      estatStmc: [],
      estatDc: []
    });
    this.currentPage = 1;
    await this.fetchData()
  }

  async onItemsPerPageChange(event: Event) {
    this.currentPage = 1;
    this.itemsPerPage = +(event.target as HTMLSelectElement).value;
    await this.fetchData(this.formData.value)
  }

  previousPage() {
    if (this.currentPage > 1) {
      this.currentPage--;
      this.fetchData(this.formData.value)
    }
  }

  nextPage() {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
      this.fetchData(this.formData.value)
    }
  }

  goToPage(event: Event) {
    this.currentPage = +(event.target as HTMLSelectElement).value;
    this.fetchData(this.formData.value)
  }

  async sortFieldChange(event: any) {
    this.sortField = event.target.value;
    await this.updateSortField(this.sortField);
  }

  async updateSortField(field: string) {
    this.currentPage = 1;
    if( this.sortField == field){
      this.sortOrder = !this.sortOrder;
    } else {
      this.sortField = field;
    }
    await this.fetchData(this.formData.value);
  }

  async changeOrder() {
    this.currentPage = 1;
    this.sortOrder = !this.sortOrder;
    await this.fetchData(this.formData.value);
  }
  
}
