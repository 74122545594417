import {
  Component,
  Inject,
  EventEmitter,
  Output,
  inject,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { HabitualsEditComponent } from './habitualsEdit.component';
import { lastValueFrom } from 'rxjs';
import { TransportistaService } from '../../services/api/transportista.service';
import { RutaService } from '../../services/api/ruta.service';
import { ErrorModalComponent } from '../error-modal/error-modal.component';
import { HabitualService } from '../../services/habitual.service';
import { VehicleService } from '../../services/api/vehicle.service';
import { Ruta } from '../../types/Ruta';
import { Transportista } from '../../types/Transportista';
import { Vehicle } from '../../types/Vehicle';
import { ContextService } from '../../services/api/context.service';
import { MatTooltip } from '@angular/material/tooltip';

interface ResponseMultiple {
  dadesResultat: Ruta[] | Vehicle[] | Transportista[];
  validacioResultat: any;
  dadesPaginacio: any;
}

@Component({
  selector: 'app-habituals-list',
  template: `
    <div class="ctti-modal habituals-modal">
      <div class="cross" (click)="closeModal()">
        <mat-icon> close </mat-icon>
      </div>
      @if(data.type == 'ruta') {
      <h5>{{ 'MODAL.RUTES_HABITUALS' | translate }}</h5>
      <p>{{ 'MODAL.RUTES_HABITUALS_SUB' | translate }}</p>
      } @if(data.type == 'transportista') {
      <h5>{{ 'MODAL.TRANSPORTISTES_HABITUALS' | translate }}</h5>
      <p>{{ 'MODAL.TRANSPORTISTES_HABITUALS_SUB' | translate }}</p>
      } @if(data.type == 'vehicle') {
      <h5>{{ 'MODAL.VEHICLES_HABITUALS' | translate }}</h5>
      <p>{{ 'MODAL.VEHICLES_HABITUALS_SUB' | translate }}</p>
      }

      <form
        [formGroup]="searchForm"
        class="search-bar"
        (submit)="applyFilter()"
      >
        <div class="w-100">
          <input
            type="search"
            class="ctti-input-text"
            (keyup)="applyFilter()"
            placeholder="Cercar..."
            formControlName="multiCamp"
          />
        </div>
      </form>

      <table class="ctti-table noMobile">
        <tbody>
          <tr *ngFor="let element of dataSource">
            <td (click)="selectElement(element)">
              <div class="habitual">
                {{ element.alias }}
              </div>
            </td>
            @if(data.type == 'ruta') {
            <td (click)="selectElement(element)">
              <div class="dataTaulaHabitual">
                <label>{{ 'CARREGADOR.ORIGEN' | translate }}:</label>
                <p>{{ element.poblacioOrigen }}</p>
              </div>
            </td>
            <td (click)="selectElement(element)">
              <div class="dataTaulaHabitual">
                <label>{{ 'CARREGADOR.DESTI' | translate }}:</label>
                <p>{{ element.poblacioDesti }}</p>
              </div>
            </td>
            } @if(data.type == 'transportista') {
            <td (click)="selectElement(element)">
              <div class="dataTaulaHabitual">
                <label>{{ 'STMC.RAO_SOCIAL' | translate }}:</label>
                <p>{{ element.raoSocial }}</p>
              </div>
            </td>
            <td (click)="selectElement(element)">
              <div class="dataTaulaHabitual">
                <label>{{ 'STMC.COMPANY_NIF' | translate }}:</label>
                <p>{{ element.nifTransportista }}</p>
              </div>
            </td>
            } @if(data.type == 'vehicle') {
            <td (click)="selectElement(element)">
              <div class="dataTaulaHabitual">
                <label>{{ 'STMC.MATRICULA' | translate }}:</label>
                <p>{{ element.matricula }}</p>
              </div>
            </td>
            <td>
              <div class="dataTaulaHabitual">
                <label>{{ 'STMC.DESCRIPCIO' | translate }}</label>
                <p>{{ element.observacions }}</p>
              </div>
            </td>
            <td (click)="selectElement(element)">
              <div class="dataTaulaHabitual">
                <label>{{ 'STMC.TIPUS_VEHICLE' | translate }}:</label>
                <p>{{ element.tipusVehicle }}</p>
              </div>
            </td>
            }
            <td>
              <div class="actions">
                <img
                  (click)="setFavorit(element)"
                  class="corazon"
                  [src]="
                    element.favorit
                      ? '../../../assets/images/favorite_fill.png'
                      : '../../../assets/images/favorite.png'
                  "
                />
                <p class="red" (click)="editElement(element, false)">
                  <mat-icon>visibility</mat-icon>
                </p>
                <p class="red" (click)="editElement(element, true)">
                  <mat-icon>edit</mat-icon>
                </p>
                <p class="red" (click)="deleteElement(element)">
                  <mat-icon>delete</mat-icon>
                </p>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="mobile">
        <div class="habituals-list">
          @for(item of dataSource; track item) {
          <div class="habitual-item">
            <div (click)="selectElement(item)">
              <div class="habitual">
                {{ item.alias }}
              </div>
              @if(data.type == 'transportista') {
              <div>
                <label>{{ 'STMC.RAO_SOCIAL' | translate }}</label>
                <p>{{ item.raoSocial }}</p>
              </div>
              <div>
                <label>{{ 'STMC.COMPANY_NIF' | translate }}</label>
                <p>{{ item.nifTransportista }}</p>
              </div>
              } @if(data.type == 'ruta') {
              <div>
                <label>{{ 'CARREGADOR.ORIGEN' | translate }}</label>
                <p>{{ item.poblacioOrigen }}</p>
              </div>
              <div>
                <label>{{ 'CARREGADOR.DESTI' | translate }}</label>
                <p>{{ item.poblacioDesti }}</p>
              </div>

              } @if(data.type== 'vehicle') {
              <div>
                <label>{{ 'STMC.LICENSE_PLATE' | translate }}</label>
                <p>{{ item.matricula }}</p>
              </div>
              <div class="dataTaulaHabitual">
                <label>{{ 'STMC.DESCRIPCIO' | translate }}</label>
                <p>{{ item.observacions }}</p>
              </div>
              <div>
                <label>{{ 'STMC.TIPUS_VEHICLE' | translate }}</label>
                <p>{{ item.tipusVehicle }}</p>
              </div>
              }
            </div>
            <div class="actions">
              <img
                (click)="setFavorit(item)"
                class="corazon"
                [src]="
                  item.favorits
                    ? '../../../assets/images/favorite_fill.png'
                    : '../../../assets/images/favorite.png'
                "
                matTooltip="{{ 'ADMIN.FAV' | translate }}"S
                #cor="matTooltip"
                matTooltipPosition="above"
              />
              @if(data.type == 'ruta') {
              <p
                class="red"
                (click)="editElement(item, false)"
                matTooltip="{{ 'ADMIN.VEURE' | translate }}"
                #ull="matTooltip"
                matTooltipPosition="above"
                (mouseenter)="showTooltip(ull)"
                (mouseleave)="hideTooltip(ull)"
              >
                <mat-icon>visibility</mat-icon>
              </p>
              }
              <p
                class="red"
                (click)="editElement(item, true)"
                matTooltip="{{ 'ADMIN.EDITAR' | translate }}"
                #llapis="matTooltip"
                matTooltipPosition="above"
                (mouseenter)="showTooltip(llapis)"
                (mouseleave)="hideTooltip(llapis)"
              >
                <mat-icon>edit</mat-icon>
              </p>
              <p
                class="red"
                (click)="deleteElement(item)"
                matTooltip="{{ 'ADMIN.ESBORRAR' | translate }}"
                #paperera="matTooltip"
                matTooltipPosition="above"
                (mouseenter)="showTooltip(paperera)"
                (mouseleave)="hideTooltip(paperera)"
              >
                <mat-icon>delete</mat-icon>
              </p>
            </div>
          </div>
          }
          <div class="pagination-controls">
            <div class="items-page">
              <select id="itemsPerPage" (change)="onItemsPerPageChange($event)">
                <option *ngFor="let size of pageSizeOptions" [value]="size">
                  {{ size }} / {{ 'CARREGADOR.PAG' | translate }}
                </option>
              </select>
            </div>
            <div class="page-number">
              <select [value]="currentPage" (change)="goToPage($event)">
                <option *ngFor="let index of pageNumberArr" [value]="index">
                  {{ index }} @if(currentPage == index) {
                  <span class="mobile">/{{ ' ' }}{{ totalPages }}</span> }
                </option>
              </select>
              @if(currentPage !== 1) {
              <button (click)="previousPage()">
                <mat-icon>chevron_left</mat-icon>
              </button>
              } @if(currentPage !== totalPages) {
              <button (click)="nextPage()">
                <mat-icon>chevron_right</mat-icon>
              </button>
              }
            </div>
          </div>
        </div>
      </div>

      <div class="pagination-controls noMobile">
        <div class="items-page">
          <select id="itemsPerPage" (change)="onItemsPerPageChange($event)">
            <option *ngFor="let size of pageSizeOptions" [value]="size">
              {{ size }} / {{ 'CARREGADOR.PAG' | translate }}
            </option>
          </select>
          <p class="paginacio">
            {{ 'CARREGADOR.DE' | translate }} {{ totalRegistres }}
            {{ 'CARREGADOR.ELEMENTS' | translate }}
          </p>
        </div>
        <div class="page-number">
          <select [value]="currentPage" (change)="goToPage($event)">
            <option *ngFor="let index of pageNumberArr" [value]="index">
              {{ index }}
            </option>
          </select>
          <p class="paginacio">
            {{ 'CARREGADOR.DE' | translate }} {{ totalPages }}
            {{ 'CARREGADOR.PAGINES' | translate }}
          </p>
          @if(currentPage !== 1) {
          <button (click)="previousPage()">
            <mat-icon>chevron_left</mat-icon>
          </button>
          } @if(currentPage !== totalPages) {
          <button (click)="nextPage()">
            <mat-icon>chevron_right</mat-icon>
          </button>
          }
        </div>
      </div>
    </div>
  `,
})
export class HabitualsListComponent {
  rutaService = inject(RutaService);
  transportistaService = inject(TransportistaService);
  vehicleService = inject(VehicleService);
  searchForm!: FormGroup;
  contextService = inject(ContextService);
  @Output() closeEvent = new EventEmitter<void>();

  @ViewChild('cor') cor!: MatTooltip;
  @ViewChild('llapis') llapis!: MatTooltip;
  @ViewChild('ull') ull!: MatTooltip;
  @ViewChild('paperera') paperera!: MatTooltip;

  paginatedData: any[] = [];
  pageSizeOptions: number[] = [5, 10, 20];
  itemsPerPage: number = 5;
  currentPage: number = 1;
  totalPages: number = 1;
  pageNumberArr: number[] = [];
  dataSource: any[] = [];
  totalRegistres: number = 0;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { type: string },
    private dialog: MatDialog,
    private fb: FormBuilder,
    private habitualService: HabitualService
  ) {}

  async ngOnInit() {
    this.searchForm = this.fb.group({
      multiCamp: [''],
    });
    await this.fetchData(this.searchForm.value);
  }

  async fetchData(filters: any = {}) {
    if (this.currentPage === 0) {
      this.currentPage = 1;
    }
    const body = {
      dadesPaginacio: {
        numRegisters: this.itemsPerPage,
        numPagina: this.currentPage,
        ordreCamp: 'alias',
        ordreAsc: true,
      },
      filtresConsulta: { ...filters, estat: true },
    };
    body.filtresConsulta.multiCamp = this.searchForm.value.multiCamp;
    let data: ResponseMultiple = {
      dadesResultat: [],
      validacioResultat: undefined,
      dadesPaginacio: undefined,
    };
    switch (this.data.type) {
      case 'ruta':
        data = await lastValueFrom(this.rutaService.rutaFindBy(body));
        break;
      case 'vehicle':
        data = await lastValueFrom(this.vehicleService.vehicleFindBy(body));
        break;
      case 'transportista':
        data = await lastValueFrom(
          this.transportistaService.transportistaFindBy(body)
        );
    }
    this.dataSource = data.dadesResultat;
    this.totalRegistres = data.dadesPaginacio.totalRegisters;
    this.totalPages = Math.ceil(
      data.dadesPaginacio.totalRegisters / this.itemsPerPage
    );
    if (this.currentPage > this.totalPages) {
      this.currentPage = this.totalPages;
    }
    this.pageNumberArr = Array.from(
      { length: this.totalPages },
      (_, i) => i + 1
    );
  }

  showTooltip(tooltipRef: MatTooltip) {
    console.log(0)
    tooltipRef.show();
  }

  hideTooltip(tooltipRef: MatTooltip) {
    console.log(1)
    tooltipRef.hide;
  }

  async localitazcions(element: Ruta) {
    const esSoloNumeros = (str: string) => /^\d+$/.test(str);
    let origen = '',
      desti = '';
    if (element.poblacioOrigen && esSoloNumeros(element.poblacioOrigen)) {
      const resp = await lastValueFrom(
        this.contextService.getPoblacions(element.poblacioOrigen)
      );
      if (resp.length) origen = resp[0].descripcio;
    }
    if (element.poblacioDesti && esSoloNumeros(element.poblacioDesti)) {
      const resp = await lastValueFrom(
        this.contextService.getPoblacions(element.poblacioDesti)
      );
      if (resp.length) desti = resp[0].descripcio;
    }
    return [origen, desti];
  }

  closeModal() {
    this.closeEvent.emit();
  }

  selectElement(element: any) {
    this.habitualService.emitHabitual(this.data.type, element);
    this.closeEvent.emit();
  }

  editElement(element: any, edit: boolean) {
    this.closeEvent.emit();
    const dialogRef = this.dialog.open(HabitualsEditComponent, {
      disableClose: true,
      data: {
        type: this.data.type,
        data: element,
        edit,
        openList: true,
      },
    });
    dialogRef.componentInstance.closeEvent.subscribe(() => {
      this.dialog.closeAll();
    });
  }

  async deleteElement(element: any) {
    const callback = () => {
      const dialogRef = this.dialog.open(HabitualsListComponent, {
        disableClose: true,
        data: { type: this.data.type },
      });
      dialogRef.componentInstance.closeEvent.subscribe(() => {
        this.closeEvent.emit();
      });
    };
    const dialogRef = this.dialog.open(ErrorModalComponent, {
      disableClose: true,
      data: {
        title: 'MODAL.ALERT',
        message: 'STMC.SEGUR_BORRAR',
        icon: 'warning',
        showCancel: true,
      },
    });
    dialogRef.componentInstance.closeEvent.subscribe(
      async (result: { accept: boolean }) => {
        if (result.accept) {
          this.dataSource = this.dataSource.filter((e) => e !== element);
          this.applyFilter();
          switch (this.data.type) {
            case 'ruta':
              await lastValueFrom(
                this.rutaService.deleteRuta(element.objectMantId)
              );
              break;
            case 'transportista':
              await lastValueFrom(
                this.transportistaService.deleteTransportista(
                  element.objectMantId
                )
              );
              break;
            case 'vehicle':
              await lastValueFrom(
                this.vehicleService.deleteVehicle(element.objectMantId)
              );
          }
          if (element.favorit) {
            this.habitualService.emitFavotit(this.data.type, element);
          }
          this.dataSource = this.dataSource.filter(
            (el) => el.alias !== element.alias
          );
          this.closeEvent.emit();
          callback();
        } else {
          callback();
        }
      }
    );
  }

  async setFavorit(element: any) {
    element.favorit = !element.favorit;
    if (this.data.type == 'ruta') {
      await lastValueFrom(this.rutaService.updateRuta(element));
    } else if (this.data.type == 'transportista') {
      await lastValueFrom(
        this.transportistaService.updateTransportista(element)
      );
    } else {
      await lastValueFrom(this.vehicleService.updateVehicle(element));
    }
    this.habitualService.emitFavotit(this.data.type, element);
  }

  async applyFilter() {
    this.currentPage = 1;
    await this.fetchData(this.searchForm.value);
  }

  async onItemsPerPageChange(event: any) {
    this.currentPage = 1;
    this.itemsPerPage = event.target.value;
    await this.fetchData(this.searchForm.value);
  }

  async goToPage(event: any) {
    this.currentPage = event.target.value;
    await this.fetchData(this.searchForm.value);
  }

  async previousPage() {
    if (this.currentPage > 1) {
      this.currentPage--;
      await this.fetchData(this.searchForm.value);
    }
  }

  async nextPage() {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
      await this.fetchData(this.searchForm.value);
    }
  }
}
